import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://api.pricepalapp.com/api/v1',
    headers: {
        'X-API-Key': '25fd086fe09b1e83a261e393c2ab34519f8229f3de5da10c358dc3015155aed9',
        'Content-Type': 'application/json',
    }
});

export const joinWaitlist = async (email) => {
    /*try {
        const response = await apiClient.post('/join-waitlist', { email });
        return response;
    } catch (error) {
        console.warn('Error joining waitlist:', error);
        throw error;
    }*/
};
